<template>
<div class="faq">
    <input :id="id" class="question-check" type="checkbox">
    <label :for="id">
        <div class="question" @click="answerShown">
            <p>{{question}}</p>
            <div class="question-btn">
                <div class="question-btn-one"></div>
            </div>
        </div>
        <div class="answer">
            <p>{{answer}}</p>
        </div>
    </label>
</div>
</template>

<script>
export default {
    props: [
        'id',
        'question',
        'answer',
    ],
    data() {
        return {
            showAnswer: false
        }
    },
    methods: {
        answerShown() {
            this.showAnswer = !this.showAnswer
            console.log(this.showAnswer)
        }
    },

}
</script>

<style scoped>
.faq {
    width: 100%;
    position: relative;
    transition: 0.5s ease;
}

.faq input[type="checkbox"] {
    display: none;
}

.faq label {
    display: block;
    position: relative;
    overflow: hidden;
    height: 3em;
    margin-bottom: 1em;
    width: 100%;
}

.faq:nth-child(6) label{
    margin-bottom: 0;
}

.faq input[type="checkbox"]:checked+label {
    display: block;
    height: 8em;
    transition: height 0.8s;
    -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.faq:nth-child(1) input[type="checkbox"]:checked+label{
    height: 11em;
}

.faq:nth-child(2) input[type="checkbox"]:checked+label{
    height: 12em;
}

.faq:nth-child(4) input[type="checkbox"]:checked+label{
    height: 10em;
}

.faq:nth-child(5) input[type="checkbox"]:checked+label{
    height: 16em;
}

.faq:nth-child(6) input[type="checkbox"]:checked+label  {
    height: 18em;
}


.faq input[type='checkbox']:not(:checked)+label {
    display: block;
    transition: height 0.8s;
    /* height: 2.8em; */
    -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}


.question {
    border: 1px solid #FFDFDC;
    background-color: white;
    border-radius: 10px;
    width: 95.5%;
    padding: 0.625em 2%;
    padding: 2%;
    display: flex;
    z-index: 2;
    position: relative;
    align-items: center;
    justify-content: space-between;
}

.faq input[type="checkbox"]:checked+label .question {
    background-color: #FFDFDC;
    transition: 0.3s ease;
}

.question p {
    width: 97%;
    color: #0F1A41;
    font-size: 1em;
}

.question-btn {
    height: 1em;
    width: 1em;
    position: relative;
    transition: 0.5s ease;
}

.question-btn-plus {
    transform: rotate(45deg);
    transition: 0.5s ease;
}

.question-btn-one {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: 0.5s ease;
}

.question-btn-one:after {
    content: '';
    position: absolute;
    transform: translate(-50%, -50%);
    height: 2px;
    width: 100%;
    background: #EF5C4E;
    top: 50%;
    left: 50%;
    transition: 0.5s ease;
}

.question-btn-one:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #EF5C4E;
    height: 100%;
    width: 2px;
    transition: 0.5s ease;
}

.faq input[type="checkbox"]:checked+label .question-btn-one::before {
    top: 0%;
    transform: rotate(90deg);
    width: 0;
}


.answer {
    height: 0;
    opacity: 0;
    transition: 0.8s ease;
    position: relative;
    display: block;
    z-index: 1;
    border: 1px solid #FFDFDC;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: white;
    width: 95.5%;
    padding: 1em 2%;
    padding-top: 3em;
    margin-top: -2em;
    white-space:pre-wrap;
}

.faq input[type="checkbox"]:checked+label .answer {
    opacity: 1;
    height: 3em;
}

.faq:nth-child(1) input[type="checkbox"]:checked+label .answer {
    height: 6em;
}

.faq:nth-child(2) input[type="checkbox"]:checked+label .answer {
    height: 7em;
}

.faq:nth-child(4) input[type="checkbox"]:checked+label .answer {
    height: 5em;
}

.faq:nth-child(5) input[type="checkbox"]:checked+label .answer {
    height: 11em;
}

.faq:nth-child(6) input[type="checkbox"]:checked+label .answer {
    height: 13em;
}

.answer p {
    color: #0F1A41;
    font-size: 1em;
    line-height: 1.2;
    display: block;

}



/* 2304-1440 */
@media (min-width: 2304px) {
    html {
        font-size: 22px;
    }
}

/*2560-1440*/
@media (min-width: 2560px) {
    html {
        font-size: 22px;
    }
}

/*2573-1206*/
@media(min-width: 2573px) {}

/*3200-1800*/
@media(min-width: 3200px) {
    html {
        font-size: 26px;
    }
}

/*3360-1890 1695*/
@media(min-width:3360px) {}

/* 3840 x 2160 2049 (4K) */
@media(min-width:3840px){
    .question p {
        font-size: 1.15em;
    }

    .answer p {
        font-size: 1.1em;
    }
}

/*1680-1080*/
@media(max-width: 1680px) {}

/*1640-1080*/
@media(max-width: 1640px) {}

/* 1600 - 757 */
@media (max-width: 1600px) {
    .faq:nth-child(1) input[type="checkbox"]:checked+label{
        height: 12em;
    }

    .faq:nth-child(2) input[type="checkbox"]:checked+label{
        height: 13em;
    }

    .faq:nth-child(3) input[type="checkbox"]:checked+label{
        height: 9em;
    }

    .faq:nth-child(4) input[type="checkbox"]:checked+label{
        height: 11em;
    }






    .faq:nth-child(1) input[type="checkbox"]:checked+label .answer {
        height: 7em;
    }

    .faq:nth-child(2) input[type="checkbox"]:checked+label .answer {
        height: 8em;
    }

    .faq:nth-child(3) input[type="checkbox"]:checked+label .answer {
        height: 4em;
    }

    .faq:nth-child(4) input[type="checkbox"]:checked+label .answer {
        height: 6em;
    }


}

/*1536-864*/
@media(max-width:1536px) {}

/* 1440-990 */
@media(max-width:1440px) {
    .faq:nth-child(5) input[type="checkbox"]:checked+label{
        height: 17em;
    }

    .faq:nth-child(6) input[type="checkbox"]:checked+label  {
        height: 19em;
    }

    .faq:nth-child(5) input[type="checkbox"]:checked+label .answer {
        height: 12em;
    }

    .faq:nth-child(6) input[type="checkbox"]:checked+label .answer {
        height: 14em;
    }
}

/*1366-768*/
@media (max-width:1366px) {
    .question p,
    .answer p {
        font-size: 0.95em;
    }
}

/* 1280-800 */
@media (max-width:1280px) {

    .question p,
    .answer p {
        font-size: 0.9em;
    }

    .question-btn {
        height: 0.8em;
        width: 0.8em;
    }

    .faq label{
        margin-bottom: 0.5em;
    }
}

/*1134x712*/
@media(max-width:1134px) {
    .faq:nth-child(5) input[type="checkbox"]:checked+label{
        height: 15em;
    }

    .faq:nth-child(6) input[type="checkbox"]:checked+label  {
        height: 17em;
    }

    .faq:nth-child(5) input[type="checkbox"]:checked+label .answer {
        height: 10em;
    }

    .faq:nth-child(6) input[type="checkbox"]:checked+label .answer {
        height: 12em;
    }
}

/*1024-768*/
@media (max-width:1024px) {
    .faq:nth-child(4) label{
        height: 3.5em;
        margin-bottom: 1em;
    }

    .faq:nth-child(1) input[type="checkbox"]:checked+label{
        height: 13em;
    }

    .faq:nth-child(4) input[type="checkbox"]:checked+label{
        height: 14em;
    }

    .faq:nth-child(5) input[type="checkbox"]:checked+label{
        height: 16em;
    }

    .faq:nth-child(1) input[type="checkbox"]:checked+label .answer {
        height: 8em;
    }

    .faq:nth-child(4) input[type="checkbox"]:checked+label .answer {
        height: 8.5em;
    }

    .faq:nth-child(5) input[type="checkbox"]:checked+label .answer {
        height: 11em;
    }
}

/* 962x601 */
@media(max-width: 962px) {
    .faq:nth-child(4) label {
        height: 3.3em;
        margin-bottom: 1em;
    }

    .faq:nth-child(1) input[type="checkbox"]:checked+label{
        height: 14em;
    }
    
    .faq:nth-child(2) input[type="checkbox"]:checked+label{
        height: 16em;
    }

    .faq:nth-child(3) input[type="checkbox"]:checked+label{
        height: 10em;
    }

    .faq:nth-child(4) input[type="checkbox"]:checked+label{
        height: 13em;
    }

    .faq:nth-child(5) input[type="checkbox"]:checked+label{
        height: 18em;
    }

    .faq:nth-child(6) input[type="checkbox"]:checked+label  {
        height:22em;
    }




    .faq:nth-child(1) input[type="checkbox"]:checked+label .answer {
        height: 9em;
    }

    .faq:nth-child(2) input[type="checkbox"]:checked+label .answer {
        height: 11em;
    }

    .faq:nth-child(3) input[type="checkbox"]:checked+label .answer {
        height: 5em;
    }

    .faq:nth-child(4) input[type="checkbox"]:checked+label .answer {
        height: 7em;
    }

    .faq:nth-child(5) input[type="checkbox"]:checked+label .answer {
        height: 13em;
    }

    .faq:nth-child(6) input[type="checkbox"]:checked+label .answer {
        height: 17em;
    }
}

/*834-1112*/
@media(max-width: 834px) {

}

/* 810x1080 */
@media(max-width: 810px) {}

/* 800x1280 */
@media(max-width: 800px) {}

/*778-304*/
@media(max-width: 778px) {}

/* 768x1024 */
@media(max-width: 768px) {
    .question p,
    .answer p {
        font-size: 1em;
    }

    .faq:nth-child(4) label{
    height: 3em;
    margin-bottom: 0.5em;
}


    .faq:nth-child(1) input[type="checkbox"]:checked+label{
        height: 12em;
    }
    
    .faq:nth-child(2) input[type="checkbox"]:checked+label{
        height: 14em;
    }

    .faq:nth-child(3) input[type="checkbox"]:checked+label{
        height: 9em;
    }

    .faq:nth-child(4) input[type="checkbox"]:checked+label{
        height: 11em;
    }

    .faq:nth-child(5) input[type="checkbox"]:checked+label{
        height: 16em;
    }

    .faq:nth-child(6) input[type="checkbox"]:checked+label  {
        height: 20em;
    }




    .faq:nth-child(1) input[type="checkbox"]:checked+label .answer {
        height: 7em;
    }

    .faq:nth-child(2) input[type="checkbox"]:checked+label .answer {
        height: 9em;
    }

    .faq:nth-child(3) input[type="checkbox"]:checked+label .answer {
        height: 4em;
    }

    .faq:nth-child(4) input[type="checkbox"]:checked+label .answer {
        height: 6em;
    }

    .faq:nth-child(5) input[type="checkbox"]:checked+label .answer {
        height: 11em;
    }

    .faq:nth-child(6) input[type="checkbox"]:checked+label .answer {
        height: 15em;
    }
}

/*712-1138*/
@media(max-width:712px) {}

/* 601x962 */
@media(max-width:601px) {
    .faq:nth-child(1) input[type="checkbox"]:checked+label{
        height: 14em;
    }

    .faq:nth-child(1) input[type="checkbox"]:checked+label .answer {
        height: 9em;
    }    

    .faq:nth-child(5) input[type="checkbox"]:checked+label{
        height: 18em;
    }

    .faq:nth-child(5) input[type="checkbox"]:checked+label .answer {
        height: 13em;
    }
}

/* 577-951 */
@media(max-width:577px) {

    .question p,
    .answer p {
        font-size: 0.95em;
    }
}

/* 540-960 */
@media(max-width:540px) {
    .faq:nth-child(4) label {
        height: 3.5em;
        margin-bottom: 1em;
    }

    .faq:nth-child(4) input[type="checkbox"]:checked+label{
        height: 13em;
    }

    .faq:nth-child(4) input[type="checkbox"]:checked+label .answer {
        height: 7em;
    }
}

/*480-800*/
@media (max-width:480px) {}

/*425-*/
@media(max-width:425px) {}

/*414-736 617   */
@media (max-width:414px) {
    .question {
        padding: 4% 2%;
    }

    .faq:nth-child(4) label{
        height: 4em;
        margin-bottom: 0.5em;
    }

    .question p,
    .answer p {
        font-size: 0.9rem;
    }

    .answer {
        padding-top: 2em;
        margin-top: -1.5em;
    } 

    .faq:nth-child(1) input[type="checkbox"]:checked+label{
        height: 14em;
    }

    .faq:nth-child(2) input[type="checkbox"]:checked+label{
        height: 16em;
    }

    .faq:nth-child(3) input[type="checkbox"]:checked+label{
        height: 10em;
    }

    .faq:nth-child(4) input[type="checkbox"]:checked+label{
        height: 14em;
    }

    .faq:nth-child(5) input[type="checkbox"]:checked+label{
        height: 18em;
    }

    .faq:nth-child(6) input[type="checkbox"]:checked+label  {
        height: 22em;
    }




    .faq:nth-child(1) input[type="checkbox"]:checked+label .answer {
        height: 9em;
    }

    .faq:nth-child(2) input[type="checkbox"]:checked+label .answer {
        height: 11em;
    }

    .faq:nth-child(3) input[type="checkbox"]:checked+label .answer {
        height: 5em;
    }

    .faq:nth-child(4) input[type="checkbox"]:checked+label .answer {
        height: 8em;
    }

    .faq:nth-child(5) input[type="checkbox"]:checked+label .answer {
        height: 13em;
    }

    .faq:nth-child(6) input[type="checkbox"]:checked+label .answer {
        height: 17em;
    }
}

/*390 x 844*/
@media(max-width:390px) {

    .question p,
    .answer p {
        font-size: 0.85rem;
    }
}

/*384-640*/
@media(max-width:384px) {}

/*375*/
@media(max-width:375px) {}

/*360x640*/
@media(max-width:360px) {
    .answer {
        padding-bottom: 0;
    }

    .question p,
    .answer p {
        font-size: 0.75rem;
    }
}

/*320x568*/
@media(max-width:320px) {}

@media (hover: hover) {
    .question:hover {
        background-color: #FFDFDC;
    }

    .question:hover .question-btn-plus {
        transform: rotate(225deg);
    }

    .question:hover {
        background-color: #FFDFDC;
    }

    .question-btn:hover {
        transform: rotate(180deg);

    }

}
</style>
