<template>
<div v-if="!mobile" class="menu">
    <div class="menu-logo">
        <router-link to="/">
            <img src="@/assets/img/logo.svg">
        </router-link>
    </div>

    <div class="menu-links">
        <a href="#offer-section">Was ist Scout</a>
        <a href="#value-cards">Dein Vorteil</a>
        <a href="#feedback">Was Kunden Sagen</a>
        <a href="#contact">Kontakt</a>
    </div>
</div>

<div class="mobile-menu-wrapper" id="mobileWrapper" v-else>
    <div class="mobile-menu">
        <div class="menu-logo">
            <router-link to="/">
                <img src="@/assets/img/logo.svg">
            </router-link>
        </div>
        <div class="burger burger-squeeze" @click="openStyle = !openStyle" :class="{open: openStyle}">
            <div class="burger-lines"></div>
        </div>
    </div>
    <transition name="mobile-menu-trans">
        <div class="mob-menu-links" v-show="openStyle">
            <a class="mob-menu-link" href="#offer-section" @click="openStyle = !openStyle">Was ist Scout</a>
            <a class="mob-menu-link" href="#value-cards" @click="openStyle = !openStyle">Dein Vorteil</a>
            <a class="mob-menu-link" href="#feedback" @click="openStyle = !openStyle">Was Kunden Sagen</a>
            <a class="mob-menu-link" href="#contact" @click="openStyle = !openStyle">Kontakt</a>
            <div class="mob-menu-lines">
                <div class="mob-menu-red"></div>
                <div class="mob-menu-blue"></div>
            </div>
            <div class="mob-menu-info">
                <div class="mob-menu-logo2">
                    <img src="@/assets/img/logo.svg">
                    <p>Dein Versicherungsscout</p>
                </div> 
                <div class="mob-newsletter">
                    <p class="mob-newsletter-title">Subscribe to our Newsletter</p>
                    <div class="newsletter newsletter-mob">
                        <input class="newsletter-input" type="email" placeholder="Enter your e-mail..." v-model="homePageData.emailNewsletter">
                        <button class="newsletter-btn newsletter-mob-btn" type="button" @click="sendEmailNewsletterNav">
                            <p>Send</p>
                        </button> 
                    </div>
                </div>
                <router-link to="/terms" class="mob-terms">Terms & Bedingungen</router-link>
                <router-link to="/datenschutz" class="mob-daten">Datenschutz-Bestimmungen</router-link>
                <p class="mob-copyright">© Scout GmbH. Alle Rechte vorbehalten.</p>
            </div>
        </div>
    </transition>
</div>

<div class="offer-section" id="offer-section">
    <div class="offer-section-lottie lottie-content">
        <Vue3Lottie class="lottie-content-lottie" :animationData="deepBlueCircle" />
        <div class="scout-search-logos">
            <div class="search-logo">
                <img src="@/assets/img/search_icon.svg">
            </div>
            <div class="logo-opacity">
                <img src="@/assets/img/logo_opacity.svg">
                <p>Dein Versicherungsscout</p>
            </div>

        </div>
    </div>
    <div class="offer-section-content">
        <h1 class="offer-section-content-title">Vergleiche online & Transparent</h1>
        <div class="key-offers">
            <div class="figure">
                <div class="key-offer key-bad-offer">
                    <h1>430.- CHF</h1>
                </div>
                <div class="key-offer key-good-offer">                    
                    <h2>Krankenkasseprämien</h2>
                    <h1>240.- CHF</h1>
                </div>
                <div class="key-offer key-mid-offer">   
                    <h2>Krankenkasseprämien</h2>                 
                    <h1>345.- CHF</h1>
                </div>
                <div class="key-offer key-bad-offer key-bad-offer2">
                    <h2>Krankenkasseprämien</h2>
                    <h1>430.- CHF</h1>
                </div>
                <div @click="$router.push('/calculator')" class="key-offer key-good-offer key-good-offer2">                    
                    <h2>Krankenkasseprämien</h2>
                    <h1>240.- CHF</h1>
                </div>
                <div class="key-offer key-mid-offer key-mid-offer2">   
                    <h2>Krankenkasseprämien</h2>                 
                    <h1>345.- CHF</h1>
                </div>  
                <div class="key-offer key-bad-offer">
                    <h1>430.- CHF</h1>
                </div>
            </div>
        </div>
        <div class="btn-wrapper">
            <router-link to="/calculator" class="offerten-btn">Vergleichen</router-link>
        </div>
    </div>
</div>

<div class="value-cards" id="value-cards">
    <div class="value-card" 
    v-motion
    :initial="{
        opacity: 0,
        y: 100,
    }"
    :visible="{
        opacity: 1,
        y: 0,
        transition:{
            delay:100
        }
    }"
    >
        <div class="value-card-img">
            <img src="@/assets/img/angebote_icon.svg">
        </div>
        <h2 class="value-card-title">Angebote</h2>
        <p class="value-card-content">
            Scout ist das Unternehmen, das Ihnen sofortigen und Transparenten Zugang zu über 50 Versicherungsgesellschaften deren Produkte und Angeboten bietet.
        </p>
    </div>

    <div class="value-card" 
    v-motion
    :initial="{
        opacity: 0,
        y: 100,
    }"
    :visible="{
        opacity: 1,
        y: 0,
        transition:{
            delay:400
        }
    }"
    
    >
        <div class="value-card-img">
            <img src="@/assets/img/vergleichen_icon.svg">
        </div>
        <h2 class="value-card-title">Vergleichen</h2>
        <p class="value-card-content">
            Nutzen Sie Scout als Ihre Plattform, um die besten Leistungen zu finden und zu vergleichen. Sehen Sie alle Vor-und Nachteile bei Versicherungsprodukten und entscheiden Sie unabhängig und faktenbasiert.
        </p>
    </div>

    <div class="value-card" 
        v-motion
        :initial="{
            opacity: 0,
            y: 100,
        }"
        :visible="{
            opacity: 1,
            y: 0,
            transition:{
                delay:700
            }
        }"
    >
        <div class="value-card-img" >
            <img src="@/assets/img/sparen_icon.svg">
        </div>
        <h2 class="value-card-title">Sparen</h2>
        <p class="value-card-content">
            Sie müssen nicht mehr von Versicherung zu Versicherung gehen, um nach den besten Angeboten zu suchen.Sehen Sie bei Scout das beste Preis-Leistungs-Verhältnis direkt und Transparent.
        </p>
    </div>
</div>

<div class="scout scout-desktop" v-if="!mobile">
    <img class="ground" src="@/assets/img/plx2/1_ground.svg">
    <img class="bg" src="@/assets/img/plx2/2_bg_forest.svg">
    <img class="bgBushes" src="@/assets/img/plx2/3_bg_bushes.svg">
    <img class="bushes" src="@/assets/img/plx2/4_bushes.svg">
    <img class="tree" src="@/assets/img/plx2/5_tree_right.svg">
    <img class="treeLeaf" src="@/assets/img/plx2/6_tree_leaf_1.svg">
    <img class="treeLeaf" src="@/assets/img/plx2/7_tree_leaf_2.svg">
    <img class="lightLeaves leaves-left leaves" src="@/assets/img/plx2/8_lightgreen_leaves_left.svg">
    <img class="lightLeaves leaves-right leaves" src="@/assets/img/plx2/9_lightgreen_leaves_right.svg">
    <img class="darkLeaves leaves-bottom" src="@/assets/img/plx2//10_deepgreen_leaves_buttom.svg">
    <img class="darkLeaves leaves-top" src="@/assets/img/plx2/11_darkgreen_leaves_top.svg">
    <img class="scoutWalking" src="@/assets/img/plx2/12_scout.svg">
    <img class="directions" src="@/assets/img/plx2/13_directional_signs.svg">
    <img class="sign" src="@/assets/img/plx2/14_wood_sign.png">
    <img class="blank" src="">
</div>
<div class="scout scout-mob" v-else>
    <img class="scout-mob-img ground " src="@/assets/img/plx_mob/1_ground.svg">
    <img class="scout-mob-img bg" src="@/assets/img/plx_mob/2_bg_forest.svg">
    <img class="scout-mob-img bgBushes " src="@/assets/img/plx_mob/3_bg_bushes.svg">
    <img class="scout-mob-img bushes" src="@/assets/img/plx_mob/4_bushes.svg">
    <img class="scout-mob-img tree" src="@/assets/img/plx_mob/5_tree_right.svg">
    <img class="scout-mob-img treeLeaf" src="@/assets/img/plx_mob/6_tree_leaf_1.svg">
    <img class="scout-mob-img treeLeaf" src="@/assets/img/plx_mob/7_tree_leaf_2.svg">
    <img class="scout-mob-img leaves lightLeaves leaves-left" src="@/assets/img/plx_mob/8_lightgreen_leaves_left.svg">
    <img class="scout-mob-img lightLeaves leaves-right leaves" src="@/assets/img/plx_mob/9_lightgreen_leaves_right.svg">
    <img class="scout-mob-img darkLeaves leaves-bottom" src="@/assets/img/plx_mob//10_deepgreen_leaves_buttom.svg">
    <img class="scout-mob-img darkLeaves leaves-top" src="@/assets/img/plx_mob/11_darkgreen_leaves_top.svg">
    <img class="scout-mob-img scoutWalking" src="@/assets/img/plx_mob/12_scout.svg">
    <img class="scout-mob-img directions" src="@/assets/img/plx_mob/13_directional_signs.svg">
    <img class="scout-mob-img sign" src="@/assets/img/plx_mob/14_wood_sign.svg">
    <img class="blank" src="">
</div>

<div class="feedback" id="feedback">
    <img v-if="!mobile" class="feedback-bg" src="@/assets/img/feedback_bg.png"
        v-motion
        :initial="{
            opacity: 0,
        }"
        :visibleOnce="{
            opacity: 1,
            transition:{
                delay:300
            }
        }"
    >
    <img v-else class="feedback-bg" src="@/assets/img/feedback_bg_mob.png"
        v-motion
        :initial="{
            opacity: 0,
            
        }"
        :visibleOnce="{
            opacity: 1,
            transition:{
                delay:300
            }
        }"
    >
    <div class="feedback-title"
        v-motion
        :initial="{
            opacity: 0,
            y: 100,
        }"
        :visibleOnce="{
            opacity: 1,
            y: 0,
            transition:{
                delay:500
            }
        }"
    >
        <h1>Was Kunden Sagen</h1>
        <p>«Wenn der Kunde an erster Stelle steht, bleibt er auch der letzte»</p>
    </div>
    <swiper 
        class="pagination-slider"
        :slidesPerView="3" 
        :breakpoints="{
            775: {
                slidesPerView: 3,
            },
            0: {
                slidesPerView: 1,
            }
        }"
        :autoplay="{
            delay: 4000,
            disableOnInteraction: true,
        }"
        :loop="true" 
        :navigation="true" 
        :modules="modules"  
        :pagination="{
            clickable:  true
        }"
        v-motion
        :initial="{
            opacity: 0,
            y: 100,
        }"
        :visibleOnce="{
            opacity: 1,
            y: 0,
            transition:{
                delay:650
            }
        }"
    >
        <swiper-slide class="pagination-slide">
            <div class="user-img">
                <img src="@/assets/img/feedback_image2.png">
            </div>
            <h2 class="user-name">Antony Smith</h2>
            <div class="user-rating">
                <i class="fa-solid fa-star full-star"></i>
                <i class="fa-solid fa-star full-star"></i>
                <i class="fa-solid fa-star full-star"></i>
                <i class="fa-solid fa-star full-star"></i>
                <i class="fa-solid fa-star not-full-star"></i>
            </div>
            <p class="user-comment">
                It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
            </p>
        </swiper-slide>
        <swiper-slide class="pagination-slide">
            <div class="user-img">
                <img src="@/assets/img/feedback_image1.png">
            </div>
            <h2 class="user-name">Michelle Belle</h2>
            <div class="user-rating">
                <i class="fa-solid fa-star full-star"></i>
                <i class="fa-solid fa-star full-star"></i>
                <i class="fa-solid fa-star full-star"></i>
                <i class="fa-solid fa-star full-star"></i>
                <i class="fa-solid fa-star not-full-star"></i>
            </div>
            <p class="user-comment">
                It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
            </p>
        </swiper-slide>
        <swiper-slide class="pagination-slide">
            <div class="user-img">
                <img src="@/assets/img/feedback_image3.png">
            </div>
            <h2 class="user-name">Andrew Martin</h2>
            <div class="user-rating">
                <i class="fa-solid fa-star full-star"></i>
                <i class="fa-solid fa-star full-star"></i>
                <i class="fa-solid fa-star full-star"></i>
                <i class="fa-solid fa-star full-star"></i>
                <i class="fa-solid fa-star not-full-star"></i>
            </div>
            <p class="user-comment">
                It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
            </p>
        </swiper-slide>
    </swiper>
</div>

<div class="partners" id="partners">
    <img v-if="!mobile" class="parterns-bg" src="@/assets/img/partner_logo_bg.png"
        v-motion
        :initial="{
            opacity: 0,
            y: 100,
        }"
        :visibleOnce="{
            opacity: 1,
            y: 0,
            transition:{
                delay:300
            }
        }"
    >
    <img v-else class="parterns-bg" src="@/assets/img/partner_bg_mob.png">
    <h1 class="partners-title"
    v-motion
    :initial="{
        opacity: 0,
        y: 100,
    }"
    :visibleOnce="{
        opacity: 1,
        y: 0,
        transition:{
            delay:450
        }
    }"
    >
        Partner
    </h1>
    <div class="white_arrow">
        <img v-if="!mobile" src="@/assets/img/white_arrow.svg"
            v-motion
            :initial="{
                opacity: 0,
                x: -200,              
            }"
            :visibleOnce="{
                opacity: 1,
                x: 0,
                transition:{
                    delay:2000
                }
            }"
        >
        <img v-else src="@/assets/img/white_arrow_mob.svg"
            v-motion
            :initial="{
                opacity: 0,
                x: -500,            
            }"
            :visibleOnce="{
                opacity: 1,
                x: 0,
                transition:{
                    delay:2000
                }
            }"
            >
    </div>
    <div class="partners-list"
    v-motion
    :initial="{
        opacity: 0,
    }"
    :visibleOnce="{
        opacity: 1,
        transition:{
            delay:700
        }
    }"
    >
        <img v-if="!mobile" src="@/assets/img/partner_logos.svg">
        <img v-else src="@/assets/img/partner_logos_mob.svg">
    </div>
</div>
<div class="contact-questions">
    <div class="contact" id="contact">
        <div class="contact-title" 
            v-motion
            :initial="{
                opacity: 0,
                y: 100,
            }"
            :visibleOnce="{
                opacity: 1,
                y: 0,
                transition:{
                    delay:300
                }
            }"
        >
            <h1>Kontakt</h1>
        </div>
        <div class="orange_arrow">
            <img v-if="!mobile" src="@/assets/img/orange_arrow.svg"
                v-motion
                :initial="{
                    opacity: 0,
                    x: -200,
                }"
                :visibleOnce="{
                    opacity: 1,
                    x: 0,
                    transition:{
                        delay:1500
                    }
                }"
            >
            <img v-else src="@/assets/img/orange_arrow_mob.svg"
                v-motion
                :initial="{
                    opacity: 0,
                    x: 200,
                }"
                :visibleOnce="{
                    opacity: 1,
                    x: 0,
                    transition:{
                        delay:1500
                    }
                }"
                
            >
        </div>
        <div class="contact-details"
            v-motion
            :initial="{
                opacity: 0,
                y: 100,
            }"
            :visibleOnce="{
                opacity: 1,
                y: 0,
                transition:{
                    delay:350
                }
            }"
        >
            <div class="contact-detail">
                <h2>Tel</h2>
                <a href="tel:4101234567">+ 41 (0) 123 456 7</a>
            </div>
            <div class="contact-detail">
                <h2>E-Mail</h2>
                <a href="mailto:info@s-cout.ch">info@s-cout.ch</a>
            </div>
        </div>
        <div class="contact-map"
            v-motion
            :initial="{
                opacity: 0,
            }"
            :visibleOnce="{
                opacity: 1,
                transition:{
                    delay:600
                }
            }"
        >
            <div class="blue-map"></div>
            <div class="orange-map"></div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2697.060236492826!2d9.109017715855765!3d47.469255605448474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479ae9278ba39561%3A0x180678138c6e7288!2sHerbergstrasse%2031c%2C%209524%20Zuzwil%2C%20Switzerland!5e0!3m2!1sen!2s!4v1666616721724!5m2!1sen!2s" width="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
    <div class="most-asked-questions" id="most-asked-questions">
        <div class="most-asked-questions-title"
            v-motion
            :initial="{
                opacity: 0,
                y: 100,
            }"
            :visibleOnce="{
                opacity: 1,
                y: 0,
                transition:{
                    delay:300
                }
            }"
        >
            <h1>Q&A</h1>
        </div>

        <div class="qa-comp"
            v-motion
            :initial="{
                opacity: 0,
            }"
            :visibleOnce="{
                opacity: 1,
                transition:{
                    delay:600
                }
            }"
        >
            <qa-component v-for="question in questions" :key="question.id" :id="question.id" :question="question.question" :answer="question.answer">
            </qa-component>
        </div>
    </div>
    <!-- <div class="socials" id="socials">
        <div class="socials-line"></div>
        <div class="social-links">
            <a target="_blank" href="http://www.twitter.com">Twitter</a>
            <a target="_blank" href="http://www.instagram.com">Instagram</a>
            <a target="_blank" href="http://www.facebook.com">Facebook</a>
        </div>
    </div> -->
    <div class="soc">
        <div class="soc-line">
            
        </div>
        <div class="soc-links">
            <a target="_blank" href="http://www.twitter.com">Twitter</a>
            <a target="_blank" href="http://www.instagram.com">Instagram</a>
            <a target="_blank" href="http://www.facebook.com">Facebook</a>
        </div>
    </div>
</div>

<footer id="footer">
    <div class="footer-lottie">
        <Vue3Lottie class="footer-lottie-lottie" :animationData="calculatorPage5Bg1" />
    </div>
    <div class="footer-container">
        <div class="footer-info">
            <div class="footer-content">
                <div class="footer-content-info">
                    <div class="footer-content-img">
                        <img src="@/assets/img/logo.svg">
                    </div>
                </div>
                <div class="footer-links">
                    <a href="#offer-section">Was ist Scout</a>
                    <a href="#value-cards">Dein Vorteil</a>
                    <a href="#feedback">Was Kunden Sagen</a>
                    <a href="#contact">Kontakt</a>
                </div>
            </div>
            <div class="footer-newsletter">
                <h2>Subscribe to our Newsletter</h2>
                <div class="newsletter">
                    <input class="newsletter-input" type="email" placeholder="Enter your e-mail..." v-model="homePageData.emailNewsletter">
                    <button class="newsletter-btn" type="button" @click="sendEmailNewsletter">
                        <p>Send</p>
                    </button>
                </div>
                <p v-for="error in v6$.emailNewsletter.$errors" :key="error.$uid" class="input-error emailNewsletter-error" id="emailNewsletter-error">
                    {{error.$message}}
                </p>
            </div>
        </div>
        <div class="footer-terms">
            <p>© Scout GmbH. Alle Rechte vorbehalten.</p>
            <p>
                <router-link to="/terms" class="terms">Terms & Bedingungen</router-link> <span style="color: #0F1A41;" v-show="!mobile">/</span> <router-link to="/datenschutz">Datenschutz-Bestimmungen</router-link>
            </p>
        </div>
    </div>
</footer>
</template>

<script>
import gsap from 'gsap'
import {
    reactive,
    computed,
    ref
} from 'vue'
import {
    useVuelidate
} from '@vuelidate/core'
import {
    required,
    email,
} from '@vuelidate/validators'
import QaComponent from '@/components/QAComponent.vue'
import deepBlueCircle from '@/assets/json/deep_blue_circle.json'
import calculatorPage5Bg1 from '@/assets/json/calculator_page5_bg_1.json'
import {
    Swiper,
    SwiperSlide
} from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
    Pagination,
    Navigation,
    Autoplay
} from "swiper";

import {
    ScrollTrigger
} from "gsap/ScrollTrigger";


gsap.registerPlugin(ScrollTrigger);
export default {
    components: {
        QaComponent,
        Swiper,
        SwiperSlide,
    },
    created() {
    document.body.style.backgroundColor = "#F8F9FF";
    },
    mounted() {
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener("click", function (e) {
                e.preventDefault()
                document.querySelector(this.getAttribute("href")).scrollIntoView({
                    behavior: "smooth"
                })
            })
        })


        if(window.innerWidth <= 775){
            const menu = document.getElementById('mobileWrapper')
            var menuHeight = menu.offsetHeight
            const offerSection = document.getElementById('offer-section')
            offerSection.style.marginTop = menuHeight+"px"
            console.log(window.innerWidth)
        }


        gsap.registerPlugin(ScrollTrigger)

        let mm = gsap.matchMedia()

        mm.add("(min-width: 775px)", ()=>{
            let tline = gsap.timeline({
            scrollTrigger:{
                trigger: ".scout",
                scrub: 4,
                start: "top top",
                end: "+=400%",
                pin: true,
            }
        })


        tline
        .to(".bgBushes", 
            {
                duration: 0.5,
                opacity: "1", 
                top: "0%",
            }
        )

        .to(".bushes", 
            {
                duration: 0.5,
                opacity: "1",
                
            }
        )

        .to(".leaves",
            {
                duration: 0.5,
                left: "0",
                right: "0",
                
            }
        )

        .to(".tree", 
            {
                duration: 0.5,
                right:"0",
                
            }
        )

        .to(".treeLeaf", 
            {
                duration: 0.5,
                opacity:"1",
                
            }
        )

        .to(".sign", 
            {
                delay: 2,
                duration: 3,
                top:"0%",
                
            }
        )

        .to(".darkLeaves",{
            opacity: "1",
            delay: 2,
            duration: 3,
        })           

        .to(".scoutWalking", 
            {
                delay: 2,
                duration: 3,
                top: "0%",
                left: "0%",
                
            }
        )

        .to(".directions", 
            {
                delay: 3,
                duration: 5,
                opacity: "1",
            }
        )

        .to(".blank", 
            {
                delay: 2,
                duration: 3,
                opacity: "1"
            }
        )
        })


        mm.add("(max-width: 775px)", ()=>{
            let tline = gsap.timeline({
            scrollTrigger:{
                trigger: ".scout",
                scrub: true,
                start: "-40% top",
                // markers: true,
                // end: "+=10%",
                end: "-40%  end",
                pin: true,
                
            }
        })

        tline
        .to(".bgBushes", 
            {
                duration: 0.5,
                opacity: "1", 
            }
        )

        .to(".bushes", 
            {
                duration: 0.5,
                opacity: "1",
                
            }
        )

        .to(".leaves",
            {
                duration: 0.5,
                opacity: "1",
            }
        )

        .to(".tree", 
            {
                duration: 0.5,
                opacity: "1",  
            }
        )

        .to(".treeLeaf", 
            {
                duration: 0.5,
                opacity:"1",
            }
        )

        .to(".sign", 
            {
                delay: 2,
                duration: 3,
                opacity: "1",  
            }
        )

        .to(".darkLeaves",{
            opacity: "1",
            delay: 2,
            duration: 3,
        })           

        .to(".scoutWalking", 
            {
                delay: 2,
                duration: 3,
                opacity: "1",
                
            }
        )

        .to(".directions", 
            {
                delay: 3,
                duration: 5,
                opacity: "1",
            }
        )

        .to(".blank", 
            {
                delay: 2,
                duration: 3,
                opacity: "1"
            }
        )

        // tline
        // .to(".bgBushes", 
        //     {
        //         duration: 0.5,
        //         opacity: "1", 
        //         top: "0%",
        //     }
        // )

        // .to(".bushes", 
        //     {
        //         duration: 0.5,
        //         opacity: "1",
                
        //     }
        // )

        // .to(".leaves",
        //     {
        //         duration: 0.5,
        //         left: "0",
        //         right: "0",
                
        //     }
        // )

        // .to(".tree", 
        //     {
        //         duration: 0.5,
        //         right:"0",
                
        //     }
        // )

        // .to(".treeLeaf", 
        //     {
        //         duration: 0.5,
        //         opacity:"1",
                
        //     }
        // )

        // .to(".sign", 
        //     {
        //         delay: 2,
        //         duration: 3,
        //         top:"0%",
                
        //     }
        // )

        // .to(".darkLeaves",{
        //     opacity: "1",
        //     delay: 2,
        //     duration: 3,
        // })           

        // .to(".scoutWalking", 
        //     {
        //         delay: 2,
        //         duration: 3,
        //         top: "0%",
        //         left: "0%",
                
        //     }
        // )

        // .to(".directions", 
        //     {
        //         delay: 3,
        //         duration: 5,
        //         opacity: "1",
        //     }
        // )

        // .to(".blank", 
        //     {
        //         delay: 2,
        //         duration: 3,
        //         opacity: "1"
        //     }
        // )
        })
        
    },
    setup() {
        var questions = [{
                id: 1,
                question: 'Was wird verglichen?',
                answer: 'Scout ist ein Onlinerechner und vergleicht alle Versicherungsprämien und die Leistungen.Du entscheidest selbst,ob der Preis die Leistungen oder die Qualität im Vordergrund stehen sollbeim Vergleichen. Anhand von daten aus der gesamten Versicherungsbranchen haben wir allen Leistungen Punktebewertungen zugeteilt von 1 Schlecht bis 6 Sehr gut.'
            },
            {
                id: 2,
                question: 'Wie bekommeich die Offerten?',
                answer: 'Je nachdem, was Angefragt wird eine Offertanfrage an die ausgewählte Versicherungsgesellschaft zugestellt mit den ausgewählten Leistungen und Preisen, Sie entscheiden dann, ob Sie diese Online erhalten möchten oder ob sie eine weitere detailliertere Produkteberatung von der Versicherungsgesellschaft möchten. Scout fungiert als neutraler und transparenter Kundenorientierter Unterstützer.'
            },
            {
                id: 3,
                question: 'Von wem erhalte ich die Offerten?',
                answer: 'Die Offertenwerden von den Versicherungsgesellschaften oder derendirekten vertraglich gebunden und Autorisierten Partner zugestellt.'
            },
            {
                id: 4,
                question: 'Was ist, wenn ich noch weitere Fragen zu den Offerten habe?',
                answer: 'Bei weiteren Fragen ist Scout oder der Angebotsersteller immer für sie da, und auch bei weiteren Fragen der Ansprechpartner, entweder Telefonisch oder bei Kundenwunsch durch eine von der Versicherungsgesellschaft autorisierten Berater der vor Ort alle Offene Fragen klärt.'
            },
            {
                id: 5,
                question: 'Wie finanziert sich Scout GmbH',
                answer: 'Die Dienstleistung der Scout ist für unsere Kunden Kostenlosund unverbindlich!\n\nDa wir unseren Kunden / Nutzern dabei helfen alle Produkte zu vergleichen und das Beste auf sie Passende Produkte zu finden erhalten wir eine Vermittlungsprovision seitens der Versicherungsgesellschaften. Mit diesen einnahmen wird die digitale Plattform Scout erweitert und verbessert, um die durch und durch Transparente schnelle und Vergleichsplattform zu erweitern.'
            },
            {
                id: 6,
                question: 'Wie neutral ist die Scout GmbH',
                answer: 
                '«Wenn der Kunde an erster Stelle steht, bleibenwir esauch»\n\nUnsere Philosophie ist,die das der Kunde immer an erster stellen stehen sollte, ganz egal was für Verlockende Angebote auch Versicherungsgesellschaften für eine Priorisierende Haltung auch Zahlen. Der Markt kann anbieten was er möchte unsere Aufgabe besteht darin unseren Nutzern die maximale Transparenz und Vergleichs Übersicht zu gewähren.\n\nDa es bis dato noch keine Plattform gibt, die das anbietet ist die Neutralität unsere Grösste Fürsprecher.'
            },
        ]

        const homePageData = reactive({
            emailNewsletter: '',
        })

        const homePage = computed(() => {
            return {
                emailNewsletter: {
                    required,
                    email,
                }
            }
        })

        const v6$ = useVuelidate(homePage, homePageData)

        const sendEmailNewsletter = async () => {
            const result = await v6$.value.$validate()
            if (result) {
                alert("email submitted good")
                console.log('email clicked')
            } else {
                alert("email submitted bad")
            }

        }

        const sendEmailNewsletterNav = async () => {
            const result = await v6$.value.$validate()
            if (result) {
                alert("email submitted good")
                console.log('email clicked')
                openStyle.value = !openStyle.value
            } else {
                alert("email submitted bad")
                openStyle.value = !openStyle.value
            }

        }

        var openStyle = ref(false)

        var mobile = ref(true)
        var windowWidth = ref(null)

        function checkScreen() {
            windowWidth.value = window.innerWidth
            if (windowWidth.value <= 775) {
                mobile.value = true
                return
            }
            mobile.value = false
            return
        }

        window.addEventListener('resize', checkScreen)
        checkScreen()

        return {
            modules: [Pagination, Navigation, Autoplay],
            deepBlueCircle,
            calculatorPage5Bg1,
            questions,
            homePageData,
            v6$,
            sendEmailNewsletter,
            openStyle,
            mobile,
            checkScreen,
            sendEmailNewsletterNav,
        };
    },
}
</script>

<style scoped>
body {
    background-color: #F8F9FF !important;
}

@media (max-width: 768px){
.mob-menu-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0;
    height: 10%;
    width: 100%;
    background-color: white;
}
}


@import '../assets/styles/home.css';
@import '../assets/styles/home-responsive.css';
@import '../assets/styles/burger.css';

</style>
