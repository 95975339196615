import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/calculator',
    name: 'CalculatorPage',
    component: () => import('../views/CalculatorPage.vue'),
  },
  {
    path: '/datenschutz',
    name: 'DatenschutzPolicy',
    component: () => import('../views/DatenschutzPolicy.vue'),
  },
  {
    path: '/terms',
    name: 'TermsPolicy',
    component: () => import('../views/TermsPolicy.vue'),
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return savedPosition || {top:0}
  }
})

export default router
